<template>
  <div>
    <div class="header">
      <i class="el-icon-data-analysis">
        <span style="margin-left:12px;">数据详情</span>
      </i>
    </div>
    <div class="header-box">
      <div class="box1">
        <div class="box-title">
          <img src="../../assets/home_1.png" alt />
          <span>创客</span>
        </div>
        <div class="box-content">
          <div @click="goUser()">
            <div>累计</div>
            <div>{{ data.ck_total }}</div>
          </div>
          <div @click="goUser()">
            <div>当日新增</div>
            <div>{{ data.ck_today }}</div>
          </div>
          <div @click="goUser()">
            <div>当月新增</div>
            <div>{{ data.ck_month }}</div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box-title">
          <img src="../../assets/home_2.png" alt />
          <span>企业客户</span>
        </div>
        <div class="box-content">
          <div @click="goEnterpriseList('3')">
            <div>累计</div>
            <div>{{ data.qy_total }}</div>
          </div>
          <div @click="goEnterpriseList('')">
            <div>当月新增</div>
            <div>{{ data.qy_month }}</div>
          </div>
          <div @click="goEnterpriseList('')">
            <div>当日新增</div>
            <div>{{ data.qy_today }}</div>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="box-title">
          <img src="../../assets/home_3.png" alt />
          <span>合同</span>
        </div>
        <div class="box-content">
          <div @click="goContractEdit('3')">
            <div>待签署</div>
            <div>{{ data.contract_launching }}</div>
          </div>
          <div @click="goContract()">
            <div>当日新增</div>
            <div>{{ data.contract_today_add }}</div>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="box-title">
          <img src="../../assets/home_4.png" alt />
          <span>工资包</span>
        </div>
        <div class="box-content">
          <div @click="goTask('5')">
            <div>已完成</div>
            <div>{{ data.task_finished }}</div>
          </div>
          <div @click="goTask('3')">
            <div>待验收</div>
            <div>{{ data.task_uncheck }}</div>
          </div>
          <div @click="goTask('4')">
            <div>待结算</div>
            <div>{{ data.task_unclose }}</div>
          </div>
        </div>
      </div>
      <div class="box5">
        <div class="box-title">
          <img src="../../assets/home_5.png" alt />
          <span>发票</span>
        </div>
        <div class="box-content">
          <div @click="goTaskIndex('0', '5')">
            <div>待开票</div>
            <div>{{ data.invoice_dkp }}</div>
          </div>
          <div @click="goInvoice()">
            <div>已完结</div>
            <div>{{ data.invoice_apply_ywj }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <Echart1></Echart1>
    </div>
    <div class="block">
      <Echart2></Echart2>
    </div>
    <div class="block">
      <Echart3></Echart3>
    </div>
  </div>
</template>

<script>
// import { loadjs } from "../../lib/load"
import { api } from '/src/api/base';
export const dashboardGeneral = api()('dashboard.general.json');

export default {
  components: {
    Echart1: () => import('./echart1.vue'),
    Echart2: () => import('./echart2.vue'),
    Echart3: () => import('./echartAdd.vue'),
  },
  data () {
    return {
      data: {},
    };
  },
  async created () {
    this.getData();
  },
  methods: {
    async getData () {
      this.data = await dashboardGeneral();
    },
    goUser () {
      this.$router.push({
        path: '/system/user/list',
      });
    },
    goEnterprise () {
      this.$router.push({
        path: '/system/enterprise/list',
      });
    },
    goEnterpriseList (id) {
      console.log(id);
      this.$router.push({
        path: '/system/enterprise/list',
        query: {
          authenticateState: id,
        },
      });
    },

    goContract () {
      this.$router.push({
        path: '/system/contract/list',
      });
    },
    goContractEdit (id) {
      this.$router.push({
        path: '/system/contract/list',
        query: {
          state: id,
        },
      });
    },
    goTask (id) {
      this.$router.push({
        path: '/system/task/list',
        query: {
          state: id,
        }
      });
    },
    goTaskIndex (id, state) {
      this.$router.push({
        path: '/system/task/list',
        query: {
          invoiceApplyCode: id,
          state: state,
        },
      });
    },
    goInvoice () {
      this.$router.push({
        path: '/system/invoice/invoice-list',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px -20px;
  color: #fff;
  > div {
    border-radius: 10px;
    padding: 21px 30px;
    text-align: center;
    flex-grow: 1;
    margin: 0 20px;
    // min-width: 160px;
  }
  .box-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      margin-left: 6px;
    }
  }
  .box-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 15px;
    > div > div {
      line-height: 26px;
    }
    > div > div:last-child {
      font-weight: bold;
      font-size: 17px;
      margin-top: 6px;
    }
  }
  .box1 {
    background-image: linear-gradient(to bottom left, #f5a9ab, #ae70f4);
    flex: 1;
    box-shadow: 2px 5px 15px #c07fe1;
  }
  .box2 {
    background-image: linear-gradient(to bottom left, #f9cbbb, #f66460);
    flex: 1;
    box-shadow: 2px 5px 15px #f78079;
  }
  .box3 {
    background-image: linear-gradient(to bottom left, #89eded, #7969e5);
    flex: 1;
    box-shadow: 2px 5px 15px #82acea;
  }
  .box4 {
    background-image: linear-gradient(to bottom left, #89eded, #b159d5);
    flex: 1;
    box-shadow: 2px 5px 15px #9f9de0;
  }
  .box5 {
    background-image: linear-gradient(to bottom left, #f7bee4, #fa4691);
    flex: 1;
    box-shadow: 2px 5px 15px #f880b9;
  }
  .block {
    margin-bottom: 40px;
  }
}
</style>
